/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { SearchParams, Tag } from "src/services";
import baseQuery from "./baseQuery";
import buildParameters, { PaginatedResponse } from "./utils";
import saveAs from "file-saver";
import { ExportType } from "src/types/enums/ExportRequest";

interface GetAllExportsParams {
  searchParams: SearchParams;
}

interface PWSSubmissionPeriodExportParams {
  params: SearchParams;
  submissionPeriodId: number;
}

export interface SubmissionDetailsExportParams {
  params: SearchParams;
  submissionId: string;
}

interface ExportDownloadRequest {
  id: number;
  name: string;
}

interface SDWISExportParams {
  submissionPeriodId: number;
  exportType: ExportType;
}

export enum ExportStatus {
  Failed = "Failed",
  GatheringData = "GatheringData",
  CreatingFile = "CreatingFile",
  Completed = "Completed",
  Canceled = "Cancelled",
}

export interface Export extends Record<string, unknown> {
  id: number;
  accountId: number;
  legacyId: number;
  filters: Record<string, unknown>;
  metaData: Record<string, string>;
  status: ExportStatus;
  errors: string[];
  type: ExportType;
  createdBy: string;
  createdOn: string;
  createdByUsername: string;
  modifiedBy: string;
  modifiedOn: Date;
  fileName: string;
  rowCount: number;
}

export const exportServiceApi = createApi({
  baseQuery: baseQuery({
    baseUrl: `/platform/exports`,
  }),
  reducerPath: "exportServiceApi",
  tagTypes: [Tag.Exports],
  endpoints: (builder) => ({
    getAllExports: builder.query<PaginatedResponse<Export>, GetAllExportsParams>({
      providesTags: [Tag.Exports],
      query: ({ searchParams }) => {
        const params = buildParameters(searchParams).toString();

        return {
          method: "GET",
          url: `/state-dashboard?${params}`,
        };
      },
    }),
    downloadExport: builder.query<
    void,
    ExportDownloadRequest
  >({
    queryFn: async (data, _queryApi, _extraOptions, fetchWithBQ) => {
      const { id, name } = data;
      const url = `/${id}`;

      try {
        const response = await fetchWithBQ({
          url,
          method: "GET",
          responseHandler: async (response: { blob: () => any }) => {
            const blob = await response.blob();
            saveAs(blob, name);
            return { data: undefined };
          },
        });

        if (response.error) {
          return { error: response.error };
        }

        return { data: undefined };
      } catch (error: any) {
        return { error: { status: "FETCH_ERROR", error: error.message } };
      }
    },
  }),
    createPWSSubmissionPeriodExport: builder.mutation<Export, PWSSubmissionPeriodExportParams>(
      {
        invalidatesTags: [Tag.Exports],
        query: ({ params, submissionPeriodId }) => {
          return {
            body: { filters: params.filters, searchTerm: params.searchTerm },
            method: "POST",
            url: `/pws-submission-period/${submissionPeriodId}`,
          };
        },
      }
    ),
    createStateUtilitiesExport: builder.mutation<Export, SearchParams>(
      {
        invalidatesTags: [Tag.Exports],
        query: (params) => {
          return {
            body: { filters: params.filters, searchTerm: params.searchTerm },
            method: "POST",
            url: `/state-utilities`,
          };
        },
      }
    ),
    createSubmissionDetailsExport: builder.mutation<Export, SubmissionDetailsExportParams>(
      {
        invalidatesTags: [Tag.Exports],
        query: ({ submissionId, params }) => {
          return {
            body: { filters: params.filters, searchTerm: params.searchTerm },
            method: "POST",
            url: `/submission-details/${submissionId}`,
          };
        },
      }
    ),
    createSubmissionQuestionsExport: builder.mutation<Export, SubmissionDetailsExportParams>(
      {
        invalidatesTags: [Tag.Exports],
        query: ({ submissionId }) => {
          return {
            method: "POST",
            url: `/submission-questions/${submissionId}`,
          };
        },
      }
    ),
    createSDWISExport: builder.mutation<Export, SDWISExportParams>(
      {
        invalidatesTags: [Tag.Exports],
        query: (params) => {
          return {
            body: { ...params },
            method: "POST",
            url: `/sdwis`,
          };
        },
      }
    ),
    getSDWISCount: builder.query<number, { submissionPeriodId: number }>({
      query: ({ submissionPeriodId }) =>
        `/sdwis/${submissionPeriodId}/count`,
    }),
  }),
});

export const {
  useGetAllExportsQuery,
  useLazyDownloadExportQuery,
  useCreatePWSSubmissionPeriodExportMutation,
  useCreateStateUtilitiesExportMutation,
  useCreateSubmissionDetailsExportMutation,
  useCreateSubmissionQuestionsExportMutation,
  useCreateSDWISExportMutation,
  useGetSDWISCountQuery
} = exportServiceApi;
