import React from "react";
import ExportDropdown from "src/modules/Exports/ExportDropdown";
import { SearchParams } from "src/services";
import { useCreateStateUtilitiesExportMutation } from "src/services/export-service-api";
import { ExportType } from "src/types/enums/ExportRequest";
import { displayDownloadStarted, toastError } from "src/utils/toast";

interface ExportOptionDropdownProps {
  params: SearchParams;
}

const Exporter: React.FC<ExportOptionDropdownProps> = ({
  params
}) => {
  const [exportSubmissions] = useCreateStateUtilitiesExportMutation()
  const options = [
    { key: "utilities", text: "Utilities", value: ExportType.StateUtilities }
  ];
  const handleExport = (): void => {
    exportSubmissions(params).catch(() => toastError('Unable to generate export.'))
    displayDownloadStarted({ type: 'export' })
  };

  return (
    <ExportDropdown exportOptions={options} handleExport={handleExport}/>
  );
};

export default Exporter;
