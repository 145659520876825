import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { ExportType } from "src/types/enums/ExportRequest";
import styled from "styled-components";

interface ExportDropdownProps {
  handleExport: (exportType: ExportType) => void;
  exportOptions: Array<{
    key: string;
    text: string;
    value: ExportType;
  }>;
}

const ExportDropdown: React.FC<ExportDropdownProps> = ({
  handleExport,
  exportOptions
}) => {
  const [isSelectionOpen, setIsSelectionOpen] = useState(false);

  const handleOpen = (): void => {
    setIsSelectionOpen(!isSelectionOpen);
  };

  const onExport = (exportType: ExportType): void => {
    handleExport(exportType);
    setIsSelectionOpen(false);
  };

  return (
    <>
      <StyledButton
        options={exportOptions}
        onClick={handleOpen}
        style={{
          backgroundColor: "#00B5E1",
          color: "white",
          textAlign: "center",
          padding: 10
        }}
      >
        Export
        <Icon name="dropdown" style={{ textAlign: "right" }} />
      </StyledButton>
      {isSelectionOpen && (
        <DropdownContainer>
          {exportOptions.map((option) => (
            <DropdownOption
              key={option.key}
              onClick={() => onExport(option.value)}
            >
              {option.text}
            </DropdownOption>
          ))}
        </DropdownContainer>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  background-color: rgb(0, 181, 225);
  color: white;
  font-weight: 600;
`;

const DropdownContainer = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  position: absolute;
  z-index: 1000;
`;

const DropdownOption = styled.div`
  cursor: pointer;
  padding: 8px;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export default ExportDropdown;
