import React, { useContext } from "react";
import { ModalContext } from "src/modules/Modal";
import { SearchParams } from "src/services";
import { useCreatePWSSubmissionPeriodExportMutation } from "src/services/export-service-api";
import { ExportType } from "src/types/enums/ExportRequest";
import { displayDownloadStarted, toastError } from "src/utils/toast";
import { Flags, useConfig } from "src/hooks/useConfig";
import ExportDropdown from "src/modules/Exports/ExportDropdown";

interface ExportOptionDropdownProps {
  submissionPeriodId: number;
  params: SearchParams;
}

const Exporter: React.FC<ExportOptionDropdownProps> = ({
  submissionPeriodId,
  params,
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [exportSubmissions] = useCreatePWSSubmissionPeriodExportMutation();
  const [statePortalSubmissionsExportEnabled] = useConfig(
    Flags.statePortalSubmissionsExport
  );

  const options = [
    { key: "exportAll", text: "SDWIS - All", value: ExportType.FullSDWIS },
    {
      key: "onlyMostRecent",
      text: "SDWIS - New",
      value: ExportType.MostRecentSDWIS,
    },
    ...(statePortalSubmissionsExportEnabled
      ? [
          {
            key: "exportSubmissionPeriod",
            text: "Submissions",
            value: ExportType.PWSSubmissionPeriod,
          },
        ]
      : []),
  ];

  const handleExport = (exportType: ExportType): void => {
    if (exportType === ExportType.PWSSubmissionPeriod) {
      exportSubmissions({ params, submissionPeriodId }).catch(() => toastError('Unable to generate export.'))
      displayDownloadStarted({ type: 'export' })
    } else {
      openModal("exportModal", {
        exportType,
        submissionPeriodId,
        closeModal,
        params
      });
    }
  };

  return (
    <ExportDropdown handleExport={handleExport} exportOptions={options} />
  );
};

export default Exporter;
