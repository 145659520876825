import {
  FilterOptions,
  FilterType,
  Table as WWTable,
} from "@120wateraudit/waterworks";
import React, { useContext } from "react";

import { TableContext } from "src/hooks/useTableState";
import Sort from "src/types/Sort";
import ServiceLine from "src/types/ServiceLine";
import {
  useDeleteSavedViewMutation,
  useGetAllSubmissionServiceLinesQuery,
  useGetSavedViewsQuery,
  useGetServiceLineMaterialOptionsQuery,
} from "src/services";
import AddressCell from "src/components/Cells/AddressCell";
import MaterialBadge from "./MaterialBadge";
import LinkCell from "src/components/Cells/LinkCell";
import MaterialClassificationBadge from "src/modules/Inventory/MaterialClassificationBadge";
import { formatYesNo } from "src/utils/format";
import { trueFalseListOptions } from "src/utils/selectList";
import ServiceLineStatusBadge from "../Submissions/ServiceLines/ServiceLineStatusBadge";
import { SAVED_VIEW_CATEGORY_MAP } from "src/components/SavedViews/constants";
import { toastSuccess } from "src/utils/toast";
import { ModalContext } from "../Modal";
import { isWrite as useIsWrite } from "src/modules/User";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";

const TABLE_NAME = "inventory";

const SERVICE_LINE_STATUS_VALUES: Record<string, string> = {
  Abandoned: "Abandoned",
  InService: "In Service",
  OutOfService: "Out Of Service",
  Stubbed: "Stubbed",
};

const serviceLineStatusOptions = Object.keys(SERVICE_LINE_STATUS_VALUES).map(
  (key) => ({
    id: key,
    text: SERVICE_LINE_STATUS_VALUES[key],
    value: key,
  })
);

const CLASIFICATION_BASIS_OPTIONS = [
  { id: "other", value: "Other", text: "Other" },
  { id: "age", value: "Age", text: "Age" },
  { id: "modeling", value: "Modeling", text: "Modeling" },
  { id: "records", value: "Records", text: "Records" },
  { id: "visual", value: "Visual", text: "Visual" },
  {
    id: "visualExcavation",
    value: "Visual - Excavation",
    text: "Visual - Excavation",
  },
  { id: "visualMeter", value: "Visual - Meter", text: "Visual - Meter" },
  { id: "nA", value: "N/A", text: "N/A" },
  {
    id: "predictiveModeling",
    value: "Predictive Modeling",
    text: "Predictive Modeling",
  },
  { id: "visualSwab", value: "Visual - Swab", text: "Visual - Swab" },
  {
    id: "waterQualitySamplingTargeted",
    value: "Water Quality Sampling - Targeted",
    text: "Water Quality Sampling - Targeted",
  },
  {
    id: "waterQualitySamplingFlushed",
    value: "Water Quality Sampling - Flushed",
    text: "Water Quality Sampling - Flushed",
  },
  {
    id: "waterQualitySamplingSequential",
    value: "Water Quality Sampling - Sequential",
    text: "Water Quality Sampling - Sequential",
  },
  {
    id: "waterQualitySamplingOther",
    value: "Water Quality Sampling - Other",
    text: "Water Quality Sampling - Other",
  },
  {
    id: "waterQualitySamplingOther",
    value: 'Service Line > 2"',
    text: 'Service Line > 2"',
  },
  {
    id: "operatorInterview",
    value: "Operator Interview",
    text: "Operator Interview",
  },
  {
    id: "customerIdentification",
    value: "Customer Identification",
    text: "Customer Identification",
  },
  { id: "interpolation", value: "Interpolation", text: "Interpolation" },
];

const LCRR_TIER_OPTIONS = [
  { id: "tier1", value: "Tier 1", text: "Tier 1" },
  { id: "tier2", value: "Tier 2", text: "Tier 2" },
  { id: "tier3", value: "Tier 3", text: "Tier 3" },
  { id: "tier4", value: "Tier 4", text: "Tier 4" },
  { id: "tier5", value: "Tier 5", text: "Tier 5" },
];

const PROPERTY_CLASSIFICATION_OPTIONS = [
  { id: "undefined", value: "Undefined", text: "Undefined" },
  { id: "singleFamilyResidence", value: "Single Family Residence", text: "Single Family Residence" },
  { id: "commercial", value: "Commercial", text: "Commercial" },
  { id: "industrial", value: "Industrial", text: "Industrial" },
  { id: "public", value: "Public", text: "Public" },
  { id: "multiFamilyResidence", value: "Multi-Family Residence", text: "Multi-Family Residence" },
  { id: "elementarySchool", value: "Elementary School", text: "Elementary School" },
  { id: "secondarySchool", value: "Secondary School", text: "Secondary School" },
  { id: "childcareFacility", value: "Childcare Facility", text: "Childcare Facility" },
  { id: "nonResidential", value: "Non-Residential", text: "Non-Residential" },
  { id: "singleFamilyResidenceWChildcare", value: "Single Family Residence w/ Childcare", text: "Single Family Residence w/ Childcare" },
  { id: "multiFamilyResidenceWChildcare", value: "Multi-Family Residence w/ Childcare", text: "Multi Family Residence w/ Childcare" },
  { id: "hospitalOrMedicalCareFacility", value: "Hospital or Medical Care Facility", text: "Hospital or Medical Care Facility" },
  { id: "nursingHome", value: "Nursing Home", text: "Nursing Home" },
  { id: "other", value: "Other", text: "Other" },
];

const SENSITIVE_POPULATION_OPTIONS = [
  { id: "no", value: "No", text: "No" },
  { id: "school", value: "Yes - School", text: "Yes - School" },
  { id: "daycare", value: "Yes - Daycare", text: "Yes - Daycare" },
  { id: "elementarySchool", value: "Yes - Elementary School", text: "Yes - Elementary School" },
  { id: "multifamily", value: "Yes - Multifamily", text: "Yes - Multifamily" },
  { id: "secondarySchool", value: "Yes - Secondary School", text: "Yes - Secondary School" },
  { id: "other", value: "Yes - Other", text: "Yes - Other" },
];

const YES_NO_UNKNOWN_OPTIONS = [
  { id: "yes", value: "Yes", text: "Yes" },
  { id: "no", value: "No", text: "No" },
  { id: "unknown", value: "Unknown", text: "Unknown" },
];

const COLUMNS = [
  {
    Header: "Service Line Id",
    name: "Service Line Id",
    key: "id",
    required: true,
    sortable: true,
    accessor: ({ id, assetId, submissionId }: ServiceLine) => (
      <div>
        <LinkCell
          text={assetId}
          to={`/submissions/${submissionId}/servicelines/${id}`}
        />
      </div>
    ),
  },
  {
    Header: "Status",
    name: "Status",
    key: "serviceLineStatus",
    sortable: true,
    accessor: (s: ServiceLine) => (
      <ServiceLineStatusBadge
        status={s.serviceLineStatus}
        style={{ color: "white" }}
      />
    ),
  },
  {
    name: "Address",
    key: "address",
    sortable: false,
    accessor: ({ addressLine1, addressLine2, city, state }: ServiceLine) => (
      <AddressCell
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        city={city}
        state={state}
      />
    ),
  },
  {
    Header: "Year Built",
    name: "Year Built",
    accessor: "yearBuilt",
    key: "yearBuilt",
    sortable: true,
  },
  {
    Header: "System-Owned Material",
    name: "System-Owned Material",
    accessor: (s: ServiceLine) => (
      <MaterialBadge
        minWidth="150px"
        padding="6px"
        material={s.publicMaterial}
      />
    ),
    key: "publicMaterial",
    sortable: true,
  },
  {
    Header: "Customer-Owned Material",
    name: "Customer-Owned Material",
    accessor: (s: ServiceLine) => (
      <MaterialBadge
        minWidth="150px"
        padding="6px"
        material={s.privateMaterial}
      />
    ),
    key: "privateMaterial",
    sortable: true,
  },
  {
    Header: "LCRR Tier",
    name: "LCRR Tier",
    accessor: "lcrrTier",
    key: "lcrrTier",
    sortable: true,
  },
  {
    Header: "EPA Material Classification",
    name: "EPA Material Classification",
    key: "materialClassification",
    accessor: (s: ServiceLine) => (
      <MaterialClassificationBadge
        materialClassification={s.materialClassification}
      />
    ),
    sortable: true,
  },
  {
    Header: "System-Owned Classification Basis",
    name: "System-Owned Classification Basis",
    accessor: "publicClassificationBasis",
    key: "publicClassificationBasis",
    sortable: true,
  },
  {
    Header: "Customer-Owned Classification Basis",
    name: "Customer-Owned Classification Basis",
    accessor: "privateClassificationBasis",
    key: "privateClassificationBasis",
    sortable: true,
  },
  {
    Header: "Property Classification",
    name: "Property Classification",
    accessor: "propertyClassification",
    key: "propertyClassification",
    sortable: true,
  },
  {
    Header: "Disadvantaged Neighborhood",
    name: "Disadvantaged Neighborhood",
    accessor: (s: ServiceLine) => {
      return <>{formatYesNo(s.disadvantagedNeighborhood)}</>;
    },
    key: "disadvantagedNeighborhood",
    sortable: true,
  },
  {
    Header: "Sensitive Population",
    name: "Sensitive Population",
    accessor: "sensitivePopulation",
    key: "sensitivePopulation",
    sortable: true,
  },
  {
    Header: "System-Owned Verified",
    name: "System-Owned Verified",
    accessor: (s: ServiceLine) => {
      return <>{formatYesNo(s.publicVerified)}</>;
    },
    key: "publicVerified",
    sortable: true,
  },
  {
    Header: "Customer-Owned Verified",
    name: "Customer-Owned Verified",
    accessor: (s: ServiceLine) => {
      return <>{formatYesNo(s.privateVerified)}</>;
    },
    key: "privateVerified",
    sortable: true,
  },
  {
    Header: "Attachments",
    name: "Attachemnts",
    key: "hasAttachment",
    sortable: false,
    accessor: ({ submissionId, id, hasAttachment }: ServiceLine) => (
      <div>
        {" "}
        {hasAttachment && (
          <LinkCell
            text={"View"}
            to={`/submissions/${submissionId}/servicelines/${id}`}
          />
        )}
      </div>
    ),
  },
  {
    Header: "Previously Lead",
    name: "Previously Lead",
    accessor: "publicPreviouslyLead",
    key: "publicPreviouslyLead",
    sortable: true,
  },
  {
    Header: "System-Owned Installed Post Lead Ban Date",
    name: "System-Owned Installed Post Lead Ban Date",
    accessor: "publicInstalledAfterLeadBan",
    key: "publicInstalledAfterLeadBan",
    sortable: true,
  },
  {
    Header: "Customer-Owned Installed Post Lead Ban Date",
    name: "Customer-Owned Installed Post Lead Ban Date",
    accessor: "privateInstalledAfterLeadBan",
    key: "privateInstalledAfterLeadBan",
    sortable: true,
  },
];

interface TableProps {
  tableContext: TableContext
  submissionId?: string;
}

const InventoryTable: React.FC<TableProps> = ({
  tableContext,
  submissionId,
}) => {
  const {
    params,
    setFilters,
    setPage,
    setPageSize,
    setSearchTerm,
    setSort,
    setActiveFilters
   } = tableContext

  const { data: response, isFetching } = useGetAllSubmissionServiceLinesQuery({
    submissionId: submissionId ?? "",
    paramsData: params,
  });
  const { data: serviceLineMaterialOptions } =
    useGetServiceLineMaterialOptionsQuery({});

  const { data: savedViews = [], isLoading: isSavedViewsLoading } = useGetSavedViewsQuery({ tableName: TABLE_NAME });
  const [deleteView] = useDeleteSavedViewMutation();
  const { closeModal, openModal } = useContext(ModalContext);
  const isWrite = useIsWrite()

  const serviceLines = response?.data ?? [];
  const total = response?.count ?? 0;
  const serviceLineFilters: FilterOptions | undefined =
    serviceLineMaterialOptions?.data.map((data: string) => {
      return {
        id: data.toLowerCase().replaceAll(" ", ""),
        value: data,
        text: data,
      };
    });

  if (isSavedViewsLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <WWTable
      activeFilters={params.filters}
      allowHiddenColumns={true}
      columns={COLUMNS}
      data={serviceLines}
      filterable
      filters={[
        {
          key: "serviceLineStatus",
          label: "Status",
          type: FilterType.ListSelection,
          defaultValue: "All",
          multipleSelections: false,
          scrollingSelections: true,
          options: serviceLineStatusOptions,
        },
        {
          key: "yearBuilt",
          label: "Year Built",
          type: FilterType.Number,
        },
        {
          key: "publicMaterial",
          label: "System-Owned Material",
          type: FilterType.ListSelection,
          defaultValue: "All",
          multipleSelections: false,
          scrollingSelections: true,
          options: serviceLineFilters,
        },
        {
          key: "privateMaterial",
          label: "Customer-Owned Material",
          type: FilterType.ListSelection,
          defaultValue: "All",
          multipleSelections: false,
          scrollingSelections: true,
          options: serviceLineFilters,
        },
        {
          label: "EPA Material Classification",
          key: "materialClassification",
          type: FilterType.ListSelection,
          defaultValue: "All",
          multipleSelections: false,
          scrollingSelections: true,
          options: [
            {
              id: "leadStatusUnknown",
              value: "Lead Status Unknown",
              text: "Lead Status Unknown",
            },
            { id: "nonLead", value: "Non-lead", text: "Non-lead" },
            { id: "lead", value: "Lead", text: "Lead" },
            {
              id: "galvanizedRequiringReplacement",
              value: "Galvanized Requiring Replacement",
              text: "Galvanized Requiring Replacement",
            },
          ],
        },
        {
          label: "System-Owned Classification Basis",
          key: "publicClassificationBasis",
          type: FilterType.ListSelection,
          defaultValue: "All",
          multipleSelections: false,
          scrollingSelections: true,
          options: CLASIFICATION_BASIS_OPTIONS,
        },
        {
          label: "Customer-Owned Classification Basis",
          key: "privateClassificationBasis",
          type: FilterType.ListSelection,
          defaultValue: "All",
          multipleSelections: false,
          scrollingSelections: true,
          options: CLASIFICATION_BASIS_OPTIONS,
        },
        {
          label: "Disadvantaged Neighborhood",
          key: "disadvantagedNeighborhood",
          defaultValue: "All",
          options: trueFalseListOptions,
          type: FilterType.ListSelection,
        },
        {
          label: "System-Owned Verified",
          key: "publicVerified",
          defaultValue: "All",
          options: trueFalseListOptions,
          type: FilterType.ListSelection,
        },
        {
          label: "Customer-Owned Verified",
          key: "privateVerified",
          defaultValue: "All",
          options: trueFalseListOptions,
          type: FilterType.ListSelection,
        },
        {
          label: "Attachments",
          key: "hasAttachment",
          defaultValue: "All",
          options: trueFalseListOptions,
          type: FilterType.ListSelection,
        },
        {
          label: "LCRR Tier",
          key: "lcrrTier",
          defaultValue: "All",
          options: LCRR_TIER_OPTIONS,
          type: FilterType.ListSelection,
        },
        {
          label: "Property Classification",
          key: "propertyClassification",
          defaultValue: "All",
          options: PROPERTY_CLASSIFICATION_OPTIONS,
          type: FilterType.ListSelection,
        },
        {
          key: 'sensitivePopulation',
          label: 'Sensitive Population',
          defaultValue: "All",
          options: SENSITIVE_POPULATION_OPTIONS,
          type: FilterType.ListSelection,
        },
        {
          label: "Previously Lead",
          key: "publicPreviouslyLead",
          defaultValue: "All",
          options: YES_NO_UNKNOWN_OPTIONS,
          type: FilterType.ListSelection,
        },
        {
          label: "System-Owned Installed Post Lead Ban Date",
          key: "publicInstalledAfterLeadBan",
          defaultValue: "All",
          options: YES_NO_UNKNOWN_OPTIONS,
          type: FilterType.ListSelection,
        },
        {
          label: "Customer-Owned Installed Post Lead Ban Date",
          key: "privateInstalledAfterLeadBan",
          defaultValue: "All",
          options: YES_NO_UNKNOWN_OPTIONS,
          type: FilterType.ListSelection,
        },
      ]}
      itemName="Service Line"
      loading={isFetching}
      onFilterChanged={setFilters}
      onPageChanged={setPage}
      onPageSizeChanged={setPageSize}
      orderable={true}
      onSortChanged={setSort}
      onSearchChanged={setSearchTerm}
      page={params.page}
      pageSize={params.pageSize}
      paginated
      sort={params.sort as Sort}
      sortable
      searchable
      totalRecords={total}
      showViews
      setActiveFilters={setActiveFilters}
      viewsList={savedViews.map((sv) => ({
        id: sv.id,
        category: SAVED_VIEW_CATEGORY_MAP[sv.ownerType],
        name: sv.name,
        ...JSON.parse(sv.view),
      }))}
      onSaveView={(old, newView, setViewId) => {
        if (!old) {
          openModal("createSavedViewModal", {
            view: newView,
            close: closeModal,
            tableName: TABLE_NAME,
            setViewId,
          });
        } else {
          openModal("createOrEditSavedViewModal", {
            oldView: old,
            newView,
            close: closeModal,
            tableName: TABLE_NAME,
            setViewId,
          });
        }
      }}
      allowViewEdit={isWrite}
      onDeleteView={(view: { id: number; name: string }, remove) => {
        openModal("confirm", {
          title: "Delete Saved View",
          body: `This action will permanently delete "${view.name}". This cannot be undone.`,
          confirmButtonText: `Yes, delete "${view.name}"`,
          onConfirm: async () => {
            await deleteView({ id: view.id });
            remove();
            toastSuccess(`Deleted "${view.name}"`);
            closeModal();
          },
        });
      }}
    />
  );
};

export default InventoryTable;
