import React, { useCallback, useEffect, useState } from "react";
import { Dropdown as WWDropdown } from "@120wateraudit/waterworks";
import styled from "styled-components";
import { useGetAllActiveSubmissionPeriodsQuery } from "src/services/state-submission";
import { useLocation, useNavigate } from "react-router-dom";
type DropdownOptions = React.ComponentProps<typeof WWDropdown>["options"];

interface Props {
  setSubmissionPeriodId: (id: number) => void;
}
export const SubmissionPeriodDropdown: React.FC<Props> = ({
  setSubmissionPeriodId,
}) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [current, setCurrent] = useState<number | undefined>();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const submissionPeriodId = params.get("submissionPeriodId");
    if (submissionPeriodId) {
      setSubmissionPeriodId(parseInt(submissionPeriodId));
      setCurrent(parseInt(submissionPeriodId));
    }
  }, [search]);

  const setDeeplinkSubmissionPeriodId = useCallback(
    (id: number) => {
      const params = new URLSearchParams(search);
      params.set("submissionPeriodId", id.toString());
      navigate({ search: params.toString() }, { replace: true });
    },
    [setSubmissionPeriodId, navigate, search]
  );
  const { data: queryData } = useGetAllActiveSubmissionPeriodsQuery();

  const [dropDownOptions, setDropDownOptions] = useState<DropdownOptions>([]);

  useEffect(() => {
    if (queryData?.data && !dropDownOptions.length) {
      const opts: DropdownOptions = [];

      const { data: submissionPeriods } = queryData;

      submissionPeriods.forEach(({ id, dueDate, name, locked }) => {
        const dueDateStringToDate = new Date(dueDate);
        const monthName = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(dueDateStringToDate);
        const day = new Intl.DateTimeFormat("en-US", {
          day: "2-digit",
        }).format(dueDateStringToDate);

        const optBase = `${name} - ${monthName} ${day}, ${dueDateStringToDate.getFullYear()}`;
        const optString = locked ? `${optBase} 🔒` : optBase;

        opts.push({
          value: id.toString(),
          text: optString,
        });

        setDropDownOptions(opts);
      });
      const params = new URLSearchParams(search);
      const submissionPeriodId = params.get("submissionPeriodId");
      if (submissionPeriodId) {
        setSubmissionPeriodId(parseInt(submissionPeriodId));
        setCurrent(parseInt(submissionPeriodId));
        return;
      }
      const firstSubmissionPeriodChoiceId = submissionPeriods[0]?.id;

      // Set the submission period ID on initial render
      setSubmissionPeriodId(firstSubmissionPeriodChoiceId);
    }
  }, [queryData, setSubmissionPeriodId, dropDownOptions, setDropDownOptions]);

  return (
    <DropdownContainer>
      <DropdownLabel style={{ marginTop: "15%" }}>
        Submission Period
      </DropdownLabel>
      <Dropdown
        options={dropDownOptions}
        onChange={(event) => {
          setDeeplinkSubmissionPeriodId(parseInt(event.target.value));
        }}
        value={current}
      />
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  right: 0rem;
  position: absolute;
  top: 0rem;
`;

const DropdownLabel = styled.h5`
  margin-top: 15%;
`;

const Dropdown = styled(WWDropdown)`
  select {
    border-radius: 0.4rem;
    border: none;
    height: 2.25rem;
    min-width: 140px;
  }
`;
