import { Card } from "@120wateraudit/waterworks";
import React from "react";
import Page from "src/components/Page";
import PageHeader from "src/components/PageHeader";
import { useTableState } from "src/hooks/useTableState";
import SystemsTable from "src/modules/Systems/Table";
import styled from "styled-components";
import Exporter from "./Exporter";

const UtilitiesPage: React.FC = () => {
  const tableContext = useTableState();
  return (
    <Page
      title={
        <TopSectionWrapper>
          <PageHeader>Utilities</PageHeader>
          <div>
            <Exporter params={tableContext.params} />
          </div>
        </TopSectionWrapper>
      }
    >
      <Card style={{ marginTop: -20 }}>
        <SystemsTable tableContext={tableContext} />
      </Card>
    </Page>
  );
};

const TopSectionWrapper = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export default UtilitiesPage;
