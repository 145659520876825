import React, { useCallback, useMemo } from "react";
import Submission from "src/types/Submission";
import styled from "styled-components";

import { Form as FinalForm } from "react-final-form";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import { Button, Form } from "semantic-ui-react";
import { isApiError } from "src/utils/apiError";
import { toastSuccess, toastError } from "src/utils/toast";
import Error from "src/components/Error";
import { usePostUnpublishSubmissionsMutation } from "src/services/ptd";

interface Props {
  closeModal: () => void;
  selected?: Submission[];
  submissionId: string;
  onClose?: () => void;
}

const ModalContentContainer = styled.div`
  display: flex;
  margin: 0;
  justify-content: center;
`;

const FormContentContainer = styled(Form)`
  margin: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-weight: bold;
    padding-bottom: 10px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 1.5rem 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    width: 400%;
    margin-right: 15px;
  }
`;

const UnpublishToPtdModal: React.FC<Props> = ({
  closeModal,
  selected,
  onClose,
}) => {
  const [unpublish, { error, isError, isLoading }] =
    usePostUnpublishSubmissionsMutation();

  const isSingleSubmission = selected?.length === 1;

  const onSubmit = useCallback(async () => {
    try {
      const ids = selected?.map((s) => s.id) ?? [];
      if (ids.length === 0) {
        toastError("Please select at least one submission.");
        return;
      }
      const res = await unpublish({
        submissions: selected?.map((s) => s.id) ?? [],
      }).unwrap();

      if (isSingleSubmission) {
        const systemName = selected[0].system.name;
        res.publishedItems.length === 1 &&
          toastSuccess(
            `${systemName}'s submission has been successfully unpublished, but hang tight! This could take a few moments to reflect on the PTD.`
          );

        if (res.invalidItems.length === 1) {
          toastError(
            res.invalidItems[0].message ??
              `Unable to unpublish ${systemName}'s submission. Please try again or contact support.`
          );
        }
      } else {
        if (res.publishedItems.length > 0) {
          toastSuccess(
            `${res.publishedItems.length} submissions have been successfully unpublished, but hang tight! This could take a few moments to reflect on the PTD.`
          );
        }
        if (res.invalidItems.length > 0) {
          toastError(
            `There was an issue unpublishing ${res.invalidItems.length} submissions.`
          );
        }
      }

      onClose?.();
    } catch {
      toastError(
        "Uh oh! Something happened on our end, please wait a moment and try again."
      );
    }
    closeModal();
  }, [unpublish, selected, onClose, isSingleSubmission]);

  const errors = useMemo(() => {
    if (!error || !isError) {
      return undefined;
    }

    if (isApiError(error)) {
      return [error.data?.error];
    }

    return ["Please try again or contact support@120water.com"];
  }, [error, isError]);

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }
  return (
    <ModalContentContainer>
      <FinalForm
        onSubmit={onSubmit}
        render={({ valid, handleSubmit, submitting }) => {
          return (
            <>
              <FormContentContainer
                onSubmit={() => {
                  void handleSubmit();
                }}
              >
                <p>
                  By unpublishing, you will be removing this data from the State
                  PTD. This information can be republished at a later time.
                </p>
                <ButtonsWrapper>
                  <Button
                    type="submit"
                    disabled={submitting || isLoading || !valid}
                    color="red"
                  >
                    Unpublish
                  </Button>
                  <Button onClick={closeModal}>Cancel</Button>
                </ButtonsWrapper>
              </FormContentContainer>

              {errors && (
                <Error style={{ margin: "12px 24px" }} messages={errors} />
              )}
            </>
          );
        }}
      />
    </ModalContentContainer>
  );
};

export default UnpublishToPtdModal;
